<template>
  <div>
    <query-form
      ref="queryRef"
      :query-form.sync="queryRecordForm"
      :form="form"
    >
      <template v-slot:buttons>
        <b-button
          variant="primary"
          class="mr-1"
          @click="search"
        >Search</b-button>
        <b-button
          variant="secondary"
          class="mr-1"
          @click="reset"
        >Reset</b-button>
        <b-button
          v-hasBtn="`merchant_transaction_list`"
          variant="success"
          class="mr-1"
          @click="download"
        >Download</b-button>
      </template>
    </query-form>
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <table-list
        :table-data.sync="tableData"
        :list.sync="tableList"
        :page-num.sync="currentPage"
        :total-list.sync="totalList"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  defineComponent, ref, getCurrentInstance, watch, computed,
} from '@vue/composition-api'
import { BButton, BOverlay } from 'bootstrap-vue'
import { showToast, handlerTimestamp } from '@/libs/utils'

export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    const { $api, $router, $store } = proxy
    const showLoading = ref(false)
    // 时区
    const timeZone = computed(() => $store.state.merchant.timeZone, { immediate: true, deep: true })
    // 表单
    const queryRecordForm = ref({})
    const form = ref([
      {
        label: 'Transaction Type', itype: 'select', imodel: 'transactionType', placeholder: 'All', Options: [{ label: 'All', value: -1 }, { label: 'Payin', value: '2' }, { label: 'Payout', value: '1' }], labelKey: 'label', labelValue: 'value', defaultSelected: { label: 'All', value: -1 },
      },
      {
        label: 'Status', itype: 'select', imodel: 'status', placeholder: 'Status', Options: [{ label: 'ALL', value: -1 }, { label: 'SUCCESS', value: '1' }, { label: 'PROCESSING', value: '3' }, { label: 'REJECTED', value: '2' }, { label: 'REFUNDED', value: '4' }, { label: 'PRT-REFUND', value: '5' }], labelKey: 'label', labelValue: 'value', defaultSelected: { label: 'All', value: -1 },
      },
      {
        label: 'Time', itype: 'datetime', imodel: 'timeRangeDefault', placeholder: '', md: 4,
      },
      {
        label: 'Search Terms',
        itype: 'modeinput',
        imodel: 'searchTerms',
        placeholder: 'Enter search terms...',
        Options: [{ label: 'TRANSACTION ID', value: '1' }, { label: 'MERCHANT ID', value: '8' }, { label: 'USER ID', value: '3' }],
        labelKey: 'label',
        labelValue: 'value',
        defaultMode: { label: 'TRANSACTION ID', value: '1' },
        md: 4,
        iClass: 'mt-1',
      },
    ])
    // 列表
    const currentPage = ref(1)
    const tableList = ref([])
    const totalList = ref(0)
    const tableData = ref({
      tableColumns: [
        { key: 'transaction_id', label: 'TRANSACTION ID' },
        { key: 'amount', label: 'AMOUNT' },
        { key: 'processing_fee', label: 'PROCESSING FEE' },
        { key: 'currency', label: 'CURRENCY' },
        { key: 'status_text', label: 'STATUS' },
        { key: 'user_id', label: 'USER ID' },
        { key: 'transaction_type_text', label: 'TRANSACTION TYPE' },
        { key: 'out_trade_no', label: 'THIRD-PARTY ID' },
        { key: 'create_time', label: 'CREATE TIME' },
        { key: 'update_time', label: 'UPDATE TIME' },
      ],
      templates: [
        { key: 'transaction_id', tType: 'transaction_list_id' },
        { key: 'create_time', tType: 'datetime' },
        { key: 'update_time', tType: 'datetime' },
      ],
    })
    const getParams = isDownload => {
      const params = {
        page_no: currentPage.value,
        filter: {
          time_scope: {
            begin: '',
            end: '',
          },
          transaction_status: -1,
          transaction_type: -1,
          search_terms: {
            key: '1',
            model: 0,
          },
        },
        download: false,
      }
      if (queryRecordForm.value.searchTerms) {
        params.filter.search_terms.model = Number(queryRecordForm.value.searchTerms.modeValue)
        params.filter.search_terms.key = queryRecordForm.value.searchTerms.searchKey.trim()
      }
      if (queryRecordForm.value.timeRangeDefault && queryRecordForm.value.timeRangeDefault.length > 0) {
        const timeRangeParams = handlerTimestamp(queryRecordForm.value.timeRangeDefault[0], queryRecordForm.value.timeRangeDefault[1], timeZone.value)
        params.filter.time_scope.begin = `${timeRangeParams[0]}`
        params.filter.time_scope.end = `${timeRangeParams[1]}`
        // console.log('params.filter.time_scope => ', JSON.stringify(params.filter.time_scope))
      }
      if (queryRecordForm.value.status && queryRecordForm.value.status.value) {
        params.filter.transaction_status = Number(queryRecordForm.value.status.value)
      }
      if (queryRecordForm.value.transactionType && queryRecordForm.value.transactionType.value) {
        params.filter.transaction_type = Number(queryRecordForm.value.transactionType.value)
      }
      if (isDownload) {
        params.download = true
      }
      return params
    }
    const search = async type => {
      if (type !== 'refresh') { currentPage.value = 1 }
      showLoading.value = true
      const params = getParams()
      const res = await $api.transactionList(params)
      showLoading.value = false
      const { code, data, message } = res.data
      if (code === 200) {
        if (!data) { return }
        tableList.value = data.list
        totalList.value = Number(data.pagination.total_count)
      } else {
        showToast({ proxy: this, title: 'danger', message })
      }
    }
    const reset = () => {
      proxy.$refs.queryRef.reset()
    }
    const download = async () => {
      showLoading.value = true
      const params = getParams(true)
      const res = await $api.transactionList(params)
      showLoading.value = false
      const { code, message } = res.data
      if (code === 200) {
        $router.push({ name: 'Transaction Download' })
      } else {
        showToast({ proxy: this, title: 'danger', message })
      }
    }
    watch([currentPage], async () => { await search('refresh') })
    return {
      showLoading, queryRecordForm, form, currentPage, tableList, totalList, tableData, search, reset, download,
    }
  },
  components: {
    BButton,
    BOverlay,
  },
})
</script>
